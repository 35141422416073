// src/App.js
import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric';

function App() {
  const canvasRef = useRef(null);

  useEffect(() => {
    // Initialize Fabric.js canvas
    const canvas = new fabric.Canvas(canvasRef.current, {
      width: 1000,
      height: 1000,
      selection: false,
    });

    let isDragging = false;
    let lastPosX = 0;
    let lastPosY = 0;
    let startPosX = 0;
    let startPosY = 0;

    // Mouse down event
    canvas.on('mouse:down', (opt) => {
      const evt = opt.e;
      isDragging = false;
      startPosX = lastPosX = evt.clientX;
      startPosY = lastPosY = evt.clientY;
    });

    // Mouse move event
    canvas.on('mouse:move', (opt) => {
      const e = opt.e;
      if (e.buttons !== 1) return; // Only execute if left mouse button is pressed

      const dx = e.clientX - startPosX;
      const dy = e.clientY - startPosY;
      if (!isDragging && Math.abs(dx) + Math.abs(dy) > 5) {
        isDragging = true;
      }

      if (isDragging) {
        const vpt = canvas.viewportTransform;
        vpt[4] += e.clientX - lastPosX;
        vpt[5] += e.clientY - lastPosY;
        canvas.requestRenderAll();
      }
      lastPosX = e.clientX;
      lastPosY = e.clientY;
    });

    // Mouse up event
    canvas.on('mouse:up', (opt) => {
      if (!isDragging) {
        handleClick(opt);
      }
      isDragging = false;
    });

    // Function to handle pixel click
    const handleClick = (opt) => {
      const pointer = canvas.getPointer(opt.e);
      const x = Math.floor(pointer.x);
      const y = Math.floor(pointer.y);

      // Prompt the user
      if (window.confirm(`Draw a black pixel at (${x}, ${y})?`)) {
        const rect = new fabric.Rect({
          left: x,
          top: y,
          width: 1,
          height: 1,
          fill: 'black',
          selectable: false,
        });
        canvas.add(rect);
      }
    };

    // Mouse wheel event for zooming
    canvas.on('mouse:wheel', (opt) => {
      const delta = opt.e.deltaY;
      let zoom = canvas.getZoom();
      zoom *= 0.999 ** delta;
      zoom = Math.min(Math.max(zoom, 0.01), 20);
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });

    // Cleanup on component unmount
    return () => {
      canvas.dispose();
    };
  }, []);

  return (
    <div style={{ backgroundColor: "black" }}>
      <canvas
        ref={canvasRef}
        width={1000}
        height={1000}
        style={{ border: '1px solid black' }}
      />
    </div>
  );
}

export default App;