import React, { useState, useEffect, useRef } from 'react';
// import Web3Modal from 'web3modal';
// import WalletConnectProvider from '@walletconnect/web3-provider';
// import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
// import { Connection, PublicKey, clusterApiUrl, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useAccount, useDisconnect, http, createConfig, WagmiProvider, useWaitForTransactionReceipt } from 'wagmi'
import { mainnet, cronos, polygon, bsc, base, vechain, fantom, avalanche, hedera, etherlinkTestnet } from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query' 
import { Account } from './account.tsx'
import { WalletOptions } from './wallet-options.tsx'
import { useSignMessage } from 'wagmi';
import { sendTransaction as sendEthTransaction } from '@wagmi/core';
import { Buffer } from 'buffer';
import { coinbaseWallet, injected, walletConnect } from 'wagmi/connectors';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import './home.css';
import { ethers } from 'ethers';
// import { createIncrementalCompilerHost } from 'typescript';
import { Web3 } from 'web3';

// Solana stuff
import { TransactionMessage, VersionedTransaction, Connection, PublicKey, clusterApiUrl, LAMPORTS_PER_SOL, Transaction, SystemProgram, Keypair, sendAndConfirmTransaction } from '@solana/web3.js';
import { SolflareWalletAdapter, PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { WalletProvider, ConnectionProvider, useWallet, useConnection } from '@solana/wallet-adapter-react';
// import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import '@solana/wallet-adapter-react-ui/styles.css'; // This imports default styles of the wallet adapter UI components.
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
// import { sendTransaction } from 'viem/actions';
import { parseEther } from 'viem';

const network = WalletAdapterNetwork.Mainnet;

const wallets = [new PhantomWalletAdapter(), new SolflareWalletAdapter({ network })];

if (!window.Buffer) {
  window.Buffer = Buffer;
}

const DEBUG = false;

// const web3Modal = new Web3Modal ({
//   cacheProvider: true,
//   providerOptions: {
//     walletconnect: {
//       package: WalletConnectProvider,
//       options: {
//         infuraId: "abc"
//       }
//     },
    
//   }
// })

export const config = createConfig({
  chains: [
    mainnet,
    cronos,
    polygon,
    // bsc,
    base,
    // vechain,
    // fantom,
    // avalanche,
    // hedera
  ],
  connectors: [
    coinbaseWallet({
      appName: 'Web3 Place',
    }),
    // walletConnect({projectId: 'c4c6b419d8c714b400649805ed5ce697'}),
    // injected(),
    // xDefiWallet(), // For VET and other chains supported by XDEFI
    // hashpack(), // HBAR
    // phantomWallet(), // SOL
    // trustWallet(), // For BNB and other trust wallet supported chains
  ],
  transports: {
    [mainnet.id]: http(),
  },
});

export default function Home() {
  const queryClient = new QueryClient();
  const solanaWallet = useWallet();

  const gridSize = 10;
  const [colors, setColors] = useState([]);
  const [grid, setGrid] = useState(Array(gridSize).fill(Array(gridSize).fill('')));
//   const [solanaInstalled, setSolanaInstalled] = useState(false);

//   useEffect(() => {
//     const checkSolana = () => {
//         const ph = window.solana && window.solana.isPhantom;
//         const sf = window.solana && window.solana.isSolflare;

//         setSolanaInstalled(ph || sf);
//     }

//     checkSolana();
//   }, []);

  useEffect(() => {
    async function hit() {
      const colorResponse = await fetch('/colors');
      const cols = await colorResponse.json();

      setColors(cols.colors);
    }
    
    hit();
  }, []);

  // This is the grid in the background, not the canvas
  useEffect(() => {
    const intervalId = setInterval(() => {
      // console.log("Colors: " + colors.length);
      if (colors.length > 0) {
        const newGrid = grid.map(row => [...row]);
        const randomRow = Math.floor(Math.random() * gridSize);
        const randomCol = Math.floor(Math.random() * gridSize);
        const randomColor = colors[Math.floor(Math.random() * colors.length)];
        newGrid[randomRow][randomCol] = randomColor;
        setGrid(newGrid);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [grid]);

  return (
    <>
      <ConnectionProvider endpoint={clusterApiUrl(network)}>
        <WalletProvider wallets={wallets}>
          <QueryClientProvider client={queryClient}>
            <WagmiProvider config={config}>
              <Items solanaWallet={solanaWallet}/>
              <div className="grid">
                {grid.map((row, rowIndex) => (
                  <div key={rowIndex} className="row">
                    {row.map((color, colIndex) => (
                      <div key={colIndex} className={`cell pixel`} style={{backgroundColor: `${color}`}} />
                    ))}
                    </div>
                ))}
              </div>
            </WagmiProvider>
          </QueryClientProvider>
        </WalletProvider>
      </ConnectionProvider>
    </>
  );
};

// const userSignature = Cookies.get('userSignature');
// if (userSignature) {
//   // Proceed with any operation that requires the signature
// }

// const backgroundStyle = {
//   width: '100%',
//   height: '100%',
//   position: 'fixed',
//   top: 0,
//   left: 0,
//   zIndex: -1,
//   backgroundImage: 'linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), repeating-linear-gradient(to bottom, transparent, transparent 10px, rgba(0,0,0,0.1) 10px, rgba(0,0,0,0.1) 20px)',
//   maskImage: 'radial-gradient(circle, rgba(255,255,255,1) 60%, rgba(255,255,255,0))',
//   backgroundAttachment: 'fixed'
// };

// async function fetchCryptoPrice(coinId) {
//   try {
//     const url = `https://api.coingecko.com/api/v3/simple/price?ids=${coinId}&vs_currencies=usd`;
//     const response = await fetch(url);
//     const data = await response.json();
//     return data[coinId].usd;
//   } catch (error) {
//     alert("Failed to fetch price: " + error);
//   }
// }

async function fetchCryptoPrice(coinId) {
  try {
    const response = await fetch('/price?coinId=' + coinId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();

    return data.price;
  } catch (error) {
    alert("Failed to fetch price: " + error);
  }
}

const chainsData = {
  1: { name: 'Ethereum', tokens: [{ symbol: 'ETH', decimals: 18, id: "ethereum", solana: false },
    { symbol: 'USDC', decimals: 6, id: "usd-coin", solana: false },
    { symbol: 'USDT', decimals: 6, id: "tether", solana: false }] },
  25: { name: 'Cronos', tokens: [{ symbol: 'CRO', decimals: 18, id: "crypto-com-chain", solana: false }] },
  137: { name: 'Polygon', tokens: [{ symbol: 'MATIC', decimals: 18, id: "matic-network", solana: false }] },
  // 56: { name: 'Binance Smart Chain', tokens: [{ symbol: 'BNB', decimals: 18, id: "binancecoin" }] },
  // 250: { name: 'Fantom', tokens: [{ symbol: 'FTM', decimals: 18, id: "fantom" }] },
  // 43114: { name: 'Avalanche', tokens: [{ symbol: 'AVAX', decimals: 18, id: "avalanche-2" }] },
  245022926: { name: 'Solana', tokens: [{ symbol: 'SOL', decimals: 9, id: "solana", solana: true },
    { symbol: 'ZACK', decimals: 6, id: 'zack-morris', solana: true }] },
  // 8217: { name: 'VeChain', tokens: [{ symbol: 'VET', decimals: 18, id: "vechain" }, ] },
  // 0xcafe: { name: 'Hedera Hashgraph', tokens: [{ symbol: 'HBAR', decimals: 8, id: "hedera-hashgraph" }] },
  // btc: { name: 'Bitcoin', tokens: [{ symbol: 'BTC', decimals: 8, id: 'bitcoin' }]}
};

export function ShowAccount({ resetState }) {
  const { disconnect: disconnectEth } = useDisconnect();
  const { disconnect: disconnectSol, connected } = useWallet();

  const handleDisconnect = () => {
    if (connected) {
      disconnectSol();
      resetState();
    }
    else {
      disconnectEth();
      resetState();
    }
  }

  const { address } = useAccount();
  // const { disconnect } = useDisconnect();

  return (
    <div>
      {address && <div>{`Connected wallet: ${address}`}</div>}
      <button className="click" onClick={() => handleDisconnect()}>Sign out</button>
    </div>
  )
}

const Items = ({ solanaWallet }) => {
  
  const wallet = useWallet();
  const { publicKey, sendTransaction, connected } = useWallet();
  const { connection } = useConnection();

  const { signMessageAsync } = useSignMessage(config);
  const [isConnected, setIsConnected] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [amount, setAmount] = useState(0);
  const [pfield, setpfield] = useState("");
  const [verifyField, setVerifyField] = useState("");
  const { chainId } = useAccount();
  const [usingChainId, setChainId] = useState(null);
  const [name, setName] = useState("");
  const [pixelCount, setPixelCount] = useState(0);
  const [incentivesData, setIncentivesData] = useState({pixels: 0, incentives: [] });
  const { isConnected: classicConnected } = useAccount();
  const [loading, setLoading] = useState(false);

  const [phantom, setPhantom] = useState(null);
  const [status, setStatus] = useState('');

  const [solanaSendText, setSolanaSendText] = useState('');
  const [zackSendText, setZackSendText] = useState('');
  const [solanaPrice, setSolanaPrice] = useState(0);
  const [zackPrice, setZackPrice] = useState(0);

  const [sendText, setSendText] = useState('Sending transaction...');
  const [pixels, setPixels] = useState([]);

  const [txDat, setTxDat] = useState(null);

  const canvasRef = useRef(null);

  function updateConnectionState() {
    setIsConnected(connected || classicConnected);

    // alert("Connected: " + connected + " / " + classicConnected);

    if (wallet.connected) {
      setChainId(245022926);
    } else {
      setChainId(chainId);
    }
  }

  const resetState = () => {
    setIsConnected(false);
    setIsSigned(false);
    setName('');
    setVerifyField('');
    setAmount(0);
    setPhantom(null);
  }

  useEffect(() => {
    if (window.solana && window.solana.isPhantom) {
      setPhantom(window.solana);
      window.solana.connect({ onlyIfTrusted: true });
    }
  }, []);

  useEffect(() => {
    updateConnectionState();
  }, [connected, classicConnected]);

  // useEffect(() => {
  //   alert("Updated connection: " + isConnected);
  // }, [isConnected]);

  useEffect(() => {
    const fetchIncentives = async () => {
      const res = await fetch('/incentives');
      const data = await res.json();
      setIncentivesData(data);

      let run = true;
      for(let page = 1; page++ ; run) {
        await fetch(`/pixels-test?short=true&page=${page}`)
        .then(response => response.json())
        .then(pixels => {
          const canvas = canvasRef.current;
          const ctx = canvas.getContext('2d');

          const between = 5000 / data.total;

          pixels.forEach((pixel, index) => {

            // const red = index / pixels.length;

            setTimeout(() => {
              const { x, y, color } = pixel;
              ctx.fillStyle = color;
              ctx.fillRect(x + 1000 / 2, y + 1000 / 2, 1, 1); // todo: fix this when the canvas gets bigger
            }, 1);
          });
        })
        .catch(error => console.error('Error fetching pixels:', error));
      }
    }

    fetchIncentives();
  }, []);

  async function verifySignature(signature) {
    try {
      const response = await fetch((DEBUG ? 'http://localhost:5000' : '') + '/verify-signature', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          signature,
          type: (connected ? 'sol' : 'eth'),
          publicKey: (publicKey ? publicKey : '')
        }),
      });
      const data = await response.json();
      // alert("Chain ID: " + chainId);
      // alert('Verification result:' + JSON.stringify(data));
      setPixelCount(data.user.pixels);
      setVerifyField(`You currently have ${data.user.pixels} pixels. Buy more below!`);
      setName(data.user.name);
      Cookies.set('userSignature', (connected ? signature.toString('base64') : signature), { expires: 7 });
      Cookies.set('signType', (connected ? 'sol' : 'eth'), { expires: 7 });

      if (connected) {
        setSolanaPrice(await fetchCryptoPrice('solana'));
        setZackPrice(await fetchCryptoPrice('zack-morris'));
      }

      if (publicKey) {
        Cookies.set('key', publicKey, { expires: 7 });
      }
    } catch (error) {
      // alert('Error verifying signature: ' + error);
    }
  }

  const getProvider = () => {
    if ('phantom' in window) {
      const provider = window.phantom?.solana;
  
      if (provider?.isPhantom) {
        return provider;
      }
    }
  
    window.open('https://phantom.app/', '_blank');
  };
  
  
  // Bc2LDH6TwKL7WrssaAQMvE1qyJ2PipZYW6dDTkXEjGin
  function SendButton({ chainId }) {
      const tokens = chainsData[chainId]?.tokens || [];
      // const { sendTransaction: sendEthTransaction } = useSendTransaction();
      const { publicKey, sendTransaction, signTransaction } = useWallet();
      const { connection } = useConnection();

      const [chainName, setChainName] = useState('');
      let timer = null;

      const checkUserInfo = async () => {
        try {
          const response = await fetch((DEBUG ? 'http://localhost:5000' : '') + '/verify-signature', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              signature: Cookies.get('userSignature'),
              type: (connected ? 'sol' : 'eth'),
              publicKey: (publicKey ? publicKey : '')
            }),
          });
          const data = await response.json();

          if (data.user.pixels !== pixelCount) {
            setPixelCount(data.user.pixels);
            setVerifyField(`You now have ${data.user.pixels} pixels. Thanks for your purchase!`);
            setLoading(false);
            clearInterval(timer);
          }
        } catch (error) {
          alert("Error checking: " + error + " (please report this to us, wait 60 seconds, then refresh this page)");
        }
      };

      const handleSend = async (token) => {
          if (token.solana) {
            try {
                setSendText('Sending transaction... do not reload this page.');
                const price = await fetchCryptoPrice(token.id);

                // alert(token.id + " -> " + price + " -> " + ((amount * 0.25) / price) + " -> " + Number.parseFloat(((amount * 0.25) / price)).toFixed(5));
                // alert("Am: " + amount + " (" + Number.parseFloat(((amount * 0.25) / price)).toFixed(5) + ")");
  
                const response = await fetch('/send-sol', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    from: publicKey.toString(),
                    to:
                      // token.id === 'zack-morris' ?
                      // 'J3N4cdWivMpWN8wRkXUgq3Lsn26Vsb4xSgRENeXJU92H' :
                      'Bc2LDH6TwKL7WrssaAQMvE1qyJ2PipZYW6dDTkXEjGin',
                    amount: Number.parseFloat(((amount * 0.25) / price)).toFixed(5),
                    sendZack: token.id === 'zack-morris',
                  }),
                });
  
                const data = await response.json();
                let transaction = Transaction.from(Buffer.from(data.transaction, 'base64'));

                // const simulationResult = await connection.simulateTransaction(transaction);
                // console.log(simulationResult);
                // if (!simulationResult.value.err) {
                //     alert("Simulation successful");
                // } else {
                //     alert("Simulation failed with error:", simulationResult.value.err);
                // }

                const signature = await sendTransaction(transaction, connection);
                // alert("tx: " + signature);
  
                fetch('/transaction', {
                  method: 'POST',
                  headers: {'Content-Type': 'application/json'},
                  body: JSON.stringify({hash: signature, blockchain: "Solana"})
                });

                setSendText('Transaction is still sending. This page can be reloaded or closed, and you\'ll get your pixels when the transaction is sent.');
  
                setLoading(true);
                timer = setInterval(checkUserInfo, 5000);
            } catch (error) {
              alert("Error: " + error);
            }
            } else {
              try {
                setSendText('Sending transaction... do not reload this page.');
                
                const price = await fetchCryptoPrice(token.id);

                let amountToSend = ((amount * 0.25) / price).toFixed(token.decimals);
                amountToSend = parseEther(amountToSend.toString(), token.decimals);

                // await sendEthTransaction(config, {
                //     to: '0x2e53E151fF76e6EDc636366eDAEC284A762898dB',
                //     // value: amountToSend
                //     value: ethers.parseEther('0.00001'),
                //     chainId
                //   });

                  const result = await sendEthTransaction(config, {
                    to: '0x2e53E151fF76e6EDc636366eDAEC284A762898dB',
                    value: amountToSend,
                  });

                  // alert("res: " + JSON.stringify(result));
                
                  fetch('/transaction', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({hash: result, blockchain: chainsData[chainId].name})
                  });

                  setSendText('Transaction is still sending. This page can be reloaded or closed, and you\'ll get your pixels when the transaction is sent.');

                  setLoading(true);
                  timer = setInterval(checkUserInfo, 5000);               
              } catch (error) {
                alert("Error sending transaction: " + JSON.stringify(error));
              }
            }
          };

          // useEffect(() => {
          //   // alert("Pending: " + isPending + " (" + JSON.stringify(data) + ")");

          //   if (data) {
          //     // alert("Hash: " + data);

          //     fetch('/transaction', {
          //         method: 'POST',
          //         headers: {'Content-Type': 'application/json'},
          //         body: JSON.stringify({ hash: data, blockchain: chainName })
          //     })
          //     .then(response => response.json())
          //     .then(data => console.log('Transaction:', data))
          //     .catch(error => console.error('Error:', error));
          //   }
          // }, [data]);
  
      return (
        <div>
            {tokens.map(token => (
                <button key={token.symbol} className="click" onClick={() => handleSend(token)}>
                    Purchase with {token.symbol}
                </button>
            ))}
        </div>
      );
  }

  function ConnectWallet() {
    updateConnectionState();
    if (isConnected) return <ShowAccount resetState={resetState} /> 
    return <WalletOptions /> 
  }

  function SignMessageButton() {
    const { publicKey, signMessage: signMessageSol } = useWallet();
    const { signMessageAsync } = useSignMessage();

    const signMessage = async () => {
      if (connected) {
        // alert("Sign msg (sol)");
        const message = new TextEncoder().encode('Sign this message to connect to Web3 Place');
        const signature = await signMessageSol(message);

        verifySignature(signature);
        setIsSigned(true);
      } else {
        try {
        signMessageAsync({ message: 'Sign this message to connect to Web3 Place'})
        .then(data => {
          verifySignature(data);
          setIsSigned(true);
        });
        } catch (error) {
          alert(error);
        }
      }
    }

    return <button className="click" onClick={signMessage}>Connect Wallet</button>
  }

  // async function sign()
  // {
  //   await signMessageAsync({ message: 'Sign this message to connect to Web3 Place' })
  //     .then((data) =>
  //     {
  //       verifySignature(data);
  //       setIsSigned(true);
  //     });
  // }

  useEffect(() => {
    setpfield(`Cost of this purchase: $${(amount * 0.25).toFixed(2)}`);

    if (connected) {
      setSolanaTexts();
    }
  }, [amount]);

  function minusAmount() {
    if (amount > 0) {
      const newValue = parseFloat(amount - 1) || 0;
      setAmount(newValue);
    }
  }

  function addAmount() {
    const newValue = parseFloat(amount + 1) || 0;
    setAmount(newValue);
  }

  function setSolanaTexts() {
    const sol = Number.parseFloat((((amount) * 0.25) / solanaPrice)).toFixed(5);
    const zac = Number.parseFloat((((amount) * 0.25) / zackPrice)).toFixed(5);
    setSolanaSendText('Send ' + (!Number.isNaN(sol) ? sol : 0) + ' $SOL (click or tap to copy amount)');
    setZackSendText('Send ' + (!Number.isNaN(zac) ? zac : 0) + ' $ZACK (click or tap to copy amount)');
  }

  const handleTestSolSend = async () => {
    try {
      const price = await fetchCryptoPrice("solana");

      const provider = window.solana;

      await provider.connect();

      const connection = new Connection('https://web3slashplace.com/rpc');

      // const transaction = new Transaction({
      //   recentBlockhash: await connection.getRecentBlockhash(),
      // });
      // const { signature } = await provider.signAndSendTransaction(transaction);
      // await connection.getSignatureStatus(signature);

      const { blockhash } = await connection.getRecentBlockhash();

      // create array of instructions
      const instructions = [
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey('Bc2LDH6TwKL7WrssaAQMvE1qyJ2PipZYW6dDTkXEjGin'),
          lamports: 10,
        }),
      ];

      // create v0 compatible message
      const messageV0 = new TransactionMessage({
        payerKey: publicKey,
        recentBlockhash: blockhash,
        instructions,
      }).compileToV0Message();

      // make a versioned transaction
      const versionedTransaction = new VersionedTransaction(messageV0);

      const { signature } = await provider.signAndSendTransaction(versionedTransaction);
      await connection.getSignatureStatus(signature);



      // const fromPublicKey = new PublicKey(provider.publicKey.toString());
      // const toPublicKey = new PublicKey('Bc2LDH6TwKL7WrssaAQMvE1qyJ2PipZYW6dDTkXEjGin'); // Replace with recipient's public key
      // const amount = 100; // Amount in lamports (1 SOL = 1,000,000,000 lamports)
  
      // const { blockhash } = await connection.getRecentBlockhash();
  
      // // Create a new transaction
      // const transaction = new Transaction({
      //   recentBlockhash: blockhash,
      //   feePayer: fromPublicKey,
      // }).add(
      //   SystemProgram.transfer({
      //     fromPubkey: fromPublicKey,
      //     toPubkey: toPublicKey,
      //     lamports: amount,
      //   })
      // );

      // const { signature } = await provider.signAndSendTransaction(transaction);

      // const signedTransaction = await provider.signTransaction(transaction);
      // const signature = await connection.sendRawTransaction(signedTransaction.serialize());
      // await connection.confirmTransaction(signature);
    } catch (error) {
      alert("Error: " + error);
    }
  }

  const fetchRPC = async (method, params) => {
    const response = await fetch('https://web3slashplace.com/rpc', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            method,
            params,
            id: 1,
            jsonrpc: '2.0',
        }),
    });
    return response.json();
};

  function updateAmount(amount) {
    const newValue = parseFloat(amount) || 0;
    setAmount(newValue);
  }

  function updateUsername(newName) {
    // console.log("Updated name: " + newName);

    if (newName.length > 30) {
      alert("Username can only be up to 30 characters long.");
      return;
    }
    const signature = Cookies.get('userSignature');
    const type = Cookies.get('signType');

    fetch((DEBUG ? 'http://localhost:5000' : '') + '/name', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: newName, signature: (type === 'sol' ? Buffer.from(signature, 'base64') : signature), type, publicKey: Cookies.get('key') }),
    })
    
    setName(newName);
  }

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `canvas`; 
    navigate(path);
  }

  const routeBugReport = () => { 
    window.location.href = 'https://forms.gle/uFFvLPZUQPhA2xXe9';

    // let path = `https://forms.gle/NBH2E9PBs75pfMAQ7`; 
    // navigate(path);
  }

  const routeCollab = () =>{ 
    window.location.href = 'https://forms.gle/NBH2E9PBs75pfMAQ7';

    // let path = ``;
    // navigate(path);
  }

  const generateRegularMarks = (pixels) => {
    let marks = [];
    for(let i = 0; i <= pixels + 5000; i += 2500) {
      if (i >= 0) {
        marks.push({pixels: i, label: `${new Intl.NumberFormat().format(i)} pixels`});
      }
    }

    return marks;
  }

  const calculatePosition = (pixels) => {
    return `calc(50% + ${(pixels - incentivesData.pixels) / 5000 * 40}%)`;
  }

  const CommunityIncentives = ({ data }) => {
    const pixelRange = 5000; // Display range around current pixels
    const incentivesDisplay = data.incentives.filter(incentive => 
      Math.abs(incentive.pixels - data.pixels) <= pixelRange
    );

    const marks = generateRegularMarks(data.pixels);
  
    return (
      <div className="incentives-container" style={{ zIndex: '-100', position: 'relative', height: '50px', margin: '60px 0 20px' }}>
        <div className="incentives-line" style={{
            position: 'absolute',
            width: '80%', // Incentive bar is 80% of the width
            height: '10px', // Thicker bar
            backgroundColor: 'transparent',
            top: '60px', // Lower the bar to make space for text above
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: `linear-gradient(to left, rgba(255, 255, 255, 0), #4287f5 10%, #4287f5 90%, rgba(255, 255, 255, 0))`,
          }}></div>

          <div key="middle-line" className="incentive-mark" style={{ color: '#4287f5 !important', left: `calc(50%)`}}></div>

          <div style={{
            backgroundColor: 'rgba(35, 161, 31)',
            borderRadius: '12px',
            marginBottom: '100px',
            padding: '0 5px',
            fontSize: '18px'
              }}>
            <p style={{
              color: 'white',
              fontFamily: 'DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontWeight: '600'
                }}>Funding split: ${(data.pixels * 0.25 * 0.3).toFixed(2)} to $ZACK - ${(data.pixels * 0.25 * 0.2).toFixed(2)} to charity</p>
          </div>
  
        <div className="current-pixels" style={{
            position: 'absolute',
            left: '50%',
            top: '40px', // Slightly above the bar
            transform: 'translate(-50%, -100%)',
            backgroundColor: '#4287f5',
            color: '#FFFFFF',
            padding: '5px',
            borderRadius: '4px',
            fontFamily: 'DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
            fontWeight: '600'
          }}>
          {data && data.pixels ? data.pixels.toLocaleString() : '...'} placed / {data && data.total ? data.total.toLocaleString() : '...'} bought
        </div>

        {marks.map(mark => (
                    <div key={mark.pixels} className="incentive-mark" style={{ left: calculatePosition(mark.pixels)}}>
                        <div className="incentive-label" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', whiteSpace: 'nowrap', marginTop: '20px' }}>{mark.label}</div>
                    </div>
                ))}
        
        {incentivesDisplay.map(incentive => (
            <div key={incentive.id} className="incentive-mark" style={{
                left: `calc(50% + ${(incentive.pixels - data.pixels) / 5000 * 40}%)`
            }}>
                <span className="incentive-name">{incentive.name} ({new Intl.NumberFormat().format(incentive.pixels)})</span>
            </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    setSolanaTexts();
  }, []);


  // useEffect(() => {
  //   try {
  //     if (window.ethereum) {
  //       // MetaMask is installed and injected
  //       connect({ connector: injected() });
  //       alert("Connected");
  //     } else {
  //       alert("No connect");
  //     }
  //   } catch(error) {
  //     alert(error);
  //   }
  // }, []);

  // useEffect(() => {
  //   const checkEthereum = async () => {
  //     if (typeof window.ethereum !== 'undefined') {
  //       alert('MetaMask is available');
  //       // Proceed with connecting
  //       connect({ connector: injected() });
  //     } else {
  //       alert('MetaMask is not available');
  //       // Retry after a short delay
  //       setTimeout(checkEthereum, 2500); // Retry after 1 second
  //     }
  //   };
  
  //   checkEthereum(); // Check if MetaMask is available on component mount
  // }, []);

  // useEffect(async () => {
  //   try {
  //     if (isConnected && !isSigned) {
  //       if (connected) {
  //         // alert("Sign msg (sol)");
  //         const message = new TextEncoder().encode('Sign this message to connect to Web3 Place');
  //         const signature = await signMessageSol(message);

  //         verifySignature(signature);
  //         setIsSigned(true);
  //       } else {
  //         signMessageAsync({ message: 'Sign this message to connect to Web3 Place'})
  //         .then(data => {
  //           verifySignature(data);
  //           setIsSigned(true);
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     alert("Error: " + error);
  //   }
  // }, [isConnected, isSigned]);
  

  return (
        <div style={{ textAlign: 'center' }}>
          <h1 style={{ marginTop: '20px' }}>Welcome to Place!</h1>
          {/* {!isConnected && (<b>For now, please only use the in-app browser for your wallet when connecting.</b>)} */}
          {!isConnected && (<p>To connect a wallet, press any of the buttons below and then sign:</p>)}
          {/* {isConnected && (<p>Wallet connected! To connect another wallet to this one, press any of the buttons below and sign:</p>)} */}
          {!isConnected && window.solana && (<WalletMultiButton />)}
          <ConnectWallet />
          {isConnected && !isSigned && (<SignMessageButton />)}
          {isConnected && isSigned && (<p>On the Canvas, your name will show up as:</p>)}
          {isConnected && isSigned && (<input className="text-input" type="text" value={name} onChange={(e) => updateUsername(e.target.value)} />)}
          {isConnected && isSigned && (<p>{verifyField}</p>)}
          {/* {isConnected && isSigned && window.solana && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{backgroundColor: '#620275', width: '95%', borderRadius: '5px' }} onClick={() =>
              {
                navigator.clipboard.writeText('Bc2LDH6TwKL7WrssaAQMvE1qyJ2PipZYW6dDTkXEjGin');
                alert('Address copied.');
                }}>
              <p style={{color: 'white'}}>Right now, Solana-based cryptocurrencies must be sent manually. Send the selected amount of $SOL or $ZACK to <b>Bc2LDH6TwKL7WrssaAQMvE1qyJ2PipZYW6dDTkXEjGin</b> via your wallet, then reload this page shortly after. Click or tap this message to copy the wallet address.</p>
            </div>
            </div>
          )} */}
          {isConnected && isSigned && (<button className="click" onClick={minusAmount}>-</button>)}
          {isConnected && isSigned && (<input className="text-input" type="text" value={amount} onChange={(e) => updateAmount(e.target.value)} />)}
          {isConnected && isSigned && (<button className="click" onClick={addAmount}>+</button>)}
          {isConnected && isSigned && (<p>{pfield}</p>)}
          {loading && (
            <div>
              <p>{sendText}</p>
              <div className="loading-bar"></div>
            </div>
          )}
          {isConnected && isSigned && (<SendButton chainId={usingChainId} />)}
          {/* {isConnected && connected && isSigned && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
              <div style={{ backgroundColor: 'rgba(66, 135, 245)', width: '50%', borderRadius: '5px' }} onClick={() =>
              {
                navigator.clipboard.writeText(Number.parseFloat(((amount * 0.25) / solanaPrice)).toFixed(5));
                alert('$SOL amount copied.');
                }}>
                <p style={{ color: 'white' }}>{solanaSendText}</p>
              </div>
              <i style={{ margin: '10px' }}>OR</i>
              <div style={{ backgroundColor: 'rgba(35, 161, 31)', width: '50%', borderRadius: '5px' }} onClick={() =>
              {
                navigator.clipboard.writeText(Number.parseFloat(((amount * 0.25) / zackPrice)).toFixed(5));
                alert('$ZACK amount copied.');
                }}>
                <p>{zackSendText}</p>
              </div>
            </div>
          )} */}
          {isConnected && isSigned && (<br />)}
          {isConnected && isSigned && (<i>Want to use a different chain/wallet? Use the "Sign Out" button above to sign out, then re-connect using a different wallet.</i>)}
          <br />
          <button className="click" style={{
                borderRadius: '4px',
                backgroundColor: '#4287f5',
                color: '#FFFFFF',
                fontFamily: 'DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
                fontSize: '16px',
                fontWeight: '600'
                }} onClick={routeChange}>View the Canvas!</button>
          <CommunityIncentives data={incentivesData}/>

          <a href='/canvas'>
            <canvas ref={canvasRef} width="1000" height="1000" style={{ marginTop: '40px', maxWidth: '100%', backgroundColor: '#FFFFFF', border: '1px solid black'}}></canvas>
          </a>

          <br />

          <button className="click" style={{
                borderRadius: '4px',
                color: '#FFFFFF',
                fontFamily: 'DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
                fontSize: '16px',
                fontWeight: '600'
                }} onClick={routeBugReport}>Collaborate with us!</button>


          <button className="click" style={{
                borderRadius: '4px',
                color: '#FFFFFF',
                fontFamily: 'DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
                fontSize: '16px',
                fontWeight: '600'
                }} onClick={routeCollab}>Bug report or feedback</button>
        </div>
  );
};