import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import CanvasComponent from './Canvas';
import Home from './Home';
import DebugPage from './DebugPage';
import DebugCanvas from './DebugCanvas';
import FabricTest from './FabricTest';

function App() {
  return (
    <Router>
      <div>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/canvas" element={<CanvasComponent />} />
        <Route path="/test" element={<DebugPage />} />
        <Route path="/test-canvas" element={<DebugCanvas />} />
        <Route path="/test-fabric" element={<FabricTest />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;