import * as React from 'react'
import { Connector, useConnect } from 'wagmi'

export function WalletOptions() {
  const { connectors, connect } = useConnect();

  function getName(Name) {
    if (Name == "Injected")
      return "Other";
    else
      return Name;
  }

  function mainColor(Name) {
    if (Name == 'Coinbase Wallet') {
      return '#204ef4';
    } else if (Name == 'Injected') {
      return '#000000';
    } else if (Name == 'WalletConnect') {
      return '#5B6FF5';
    } else if (Name == 'MetaMask') {
      return '#E68A3C';
    } else if (Name == 'Phantom') {
      return '#A99FEC';
    }
  }

  function secondaryColor(Name) {
    return '#FFFFFF';
  }

  function order(a, b) {
    // if (a.name === 'Phantom')
    //   return -1;

    // if (b.name === 'Phantom')
    //   return -1;

    if (getName(a.name) === 'Other')
      return 1;

    if (getName(b.name) === 'Other')
      return -1;

    return 0;
  }

  return (
    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
    {/* <button className="click wallet-adapter-button wallet-adapter-button-trigger">Phantom</button> */}
      {connectors.filter((connector) => connector.name != 'Phantom').slice().sort(order).map((connector) => (
        <button className="click" style={{
            borderRadius: '4px',
            backgroundColor: mainColor(connector.name),
            color: secondaryColor(connector.name),
            fontFamily: 'DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: '16px',
            fontWeight: '600'
            }} key={connector.uid} onClick={() => connect({ connector })}>
          {getName(connector.name)}
        </button>
      ))}
    </div>
  )
}